<template>
  <CRow>

    <CCol col="12">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>
      <CCard>
        <CCardHeader>
          <back-to-list></back-to-list>
          <div class="row">
            <CCol sm="12" class="align-self-center">
              <div class="text-sm-left mt-3 mt-sm-0">
                <strong>{{ self.groupTitle }}</strong>
              </div>
              <br>
              <div class="text-sm-left mt-3 mt-sm-0">
                Poll Name : {{ self.pollName }}
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <CCollapse :show="true" :duration="400" v-for="(data,index) in userResponses">
            <CCard body-wrapper>
              <CButton
                  @click="innerCollapse = innerCollapse === index ? false : index"
                  size="sm" color="secondary">
                {{ data.option }} ({{ data.responsesCount }})
              </CButton>
              <CCollapse :show="innerCollapse === index" class="mt-2" v-for="users in data.responsesAvatar">
                <CCard body-wrapper>
                  <a @click="gotoProfile(users.userId)" style="cursor: pointer">
                    <Avatar
                        :image="users.avatar"
                        :content="users.name"
                        :isProfileListPage="true"
                        :length="20"
                    />
                  </a>

                </CCard>
              </CCollapse>
            </CCard>
          </CCollapse>

          <!--          <ul>-->
          <!--            <li v-for="data in userResponses">-->
          <!--              {{ data.option }}-->
          <!--              <ul>-->
          <!--                <li v-for="users in data.responsesAvatar">-->
          <!--                  <Avatar-->
          <!--                      :image="users.avatar"-->
          <!--                      :content="users.name"-->
          <!--                      :isProfileListPage="true"-->
          <!--                      :length="20"-->
          <!--                  />-->
          <!--                </li>-->
          <!--              </ul>-->
          <!--            </li>-->
          <!--          </ul>-->


        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {gAC} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import BackToList from "../../backToList";
import Avatar from "/src/components/Avatar";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);
export default {
  name: "groupPollUserResponses",
  mixins: [Mixin],
  components: {
    BackToList,
    Avatar
  },
  data() {
    return {
      collapse: false,
      cardCollapse: true,
      innerCollapse: false,
      accordion: 0,
      submitted: false,
      json_data: [],
      pollName: '',
      groupTitle: '',
      userResponses: [],
      messageColor: "success",
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      alertMessage: "",
      showAlert: false,
      dismissCountDownS: 0,
      msg: "",
      items: [],
      activePage: 1,
      selectedLetter: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      dismissCountDown: 0,
      statusOptions: [],
      self: this,
      module: gAC,
      comment: {
        comment: '',
      },
      maxlength: {
        // category: Maxlength.gac.name,
      },
      columns: ["name", "comment", "createdAt", "actions"],
      data: [],
      options: {
        headings: {
          name: this.$lang.poll.table.comments.name,
          comment: this.$lang.poll.table.comments.comment,
          createdAt: this.$lang.poll.table.comments.created,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["name"],
        sortable: ["name"],
        filterable: ["name"],
        // see the options API
      },
    };
  },
  validations: {},

  created() {
    let self = this;
    self.dismissCountDown = 0;
    if (this.$route.params.poll_id) {
      self.dismissCountDown = 0;
      self.listUrl = self.listUrl + "/" + this.$route.params.poll_id;
    }
    self.pollName = localStorage.getItem('pollName');
    self.groupTitle = localStorage.getItem('groupTitle');
    store.commit('showLoader', false); // Loader Start
  },
  mounted() {
    store.commit('showLoader', false); // Loader Start
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    this.viewResponses(this.$route.params.id);
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {

    gotoProfile(userId) {
      this.$router.push({path: `/profile/view/${userId}`});
    },
    viewResponses(id) {
      let self = this;
      axios.get('groups/poll/users_responses/' + id).then((response) => {
        if (response) {
          self.userResponses = response.data.data;
        } else {
          self.data = [];
        }
      });
      self.largeModal = true;
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
